body {
  margin: 0;
  font-family: "Segoe UI", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

.blackbckg {
  background-color: #000 !important;
}

h1 {
  font-size: 50px;
}

.notop {
  margin-top: 0 !important;
  padding-top: 0 !important;
}

.primary {
  color: #D50367;
}

.white {
  color: #FFF !important;
}

.ml-20 {
  margin-left: 20px;
}

.pointer{
  cursor: pointer;
}

header {
  margin-top: 50px;
  width: 100%;
  height: 80px;
  overflow-x: hidden;
}

header .left_board {
  width: 30%;
  height: 80px;
  display: inline-block;
  vertical-align: top;
}

header .right_board {
  text-align: right;
  width: 70%;
  height: 80px;
  display: inline-block;
  vertical-align: top;
}

.logo {
  height: 56px;
  margin: 12px 8px 12px 15%;
  display: inline-block;
  vertical-align: top;
}

.logo_text {
  line-height: 80px;
  display: inline-block;
  vertical-align: top;
  color: #252525;
  font-size: 29px;
  font-weight: 700;
}

header .right_board .entry {
  line-height: 80px;
  display: inline-block;
  vertical-align: top;
  font-size: 16px;
  font-weight: 500;
  color: #000;
  margin-right: 50px;
  text-decoration: none !important;
  cursor: pointer;
}

header .right_board .entry:hover {
  color: #D50367;
}

header .right_board .button {
  margin-top: 20px;
  margin-right: 50px;
  height: 40px;
  line-height: 36px;
  display: inline-block;
  vertical-align: top;
  font-size: 15px;
  font-weight: 500;
  color: #000;
  padding: 0 40px 0 16px;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #000;
  border-radius: 20px;
  cursor: pointer;
  width: fit-content;
  position: relative;
}

header .right_board .button svg {
  position: absolute;
  right: 10px;
  top: 12px;
}

header .right_board .button:hover {
  background-color: #D50367;
  color: #FFF;
  border: 1px solid #D50367;
}

.spliter_one {
  width: 25%;
  margin: 20px 5%;
  display: inline-block;
  vertical-align: top;
  overflow-x: hidden;
}

.spliter_one p {
  margin-top: 70px;
  font-size: 16px;
  color: #707070;
}

.spliter_one a {
  display: block;
  font-weight: 700;
  margin-top: 30px;
  font-size: 16px;
  color: #D50367;
  text-decoration: none;
}

.spliter_two {
  width: 55%;
  margin: 20px 5%;
  display: inline-block;
  vertical-align: top;
  overflow-x: hidden;
}

.spliter_two h1 {
  margin-top: 50px;
  font-size: 48px;
}

.blackboard {
  display: block;
  width: 100%;
  height: auto;
  background-color: #000;
  color: #FFF;
  overflow-x: hidden;
}

.blackboard .spliter_fifty {
  width: 50%;
  display: inline-block;
  vertical-align: top;
  min-height: 600px;
  position: relative;
  overflow-x: hidden;
}

.blackboard .portrait {
  display: block;
  margin: 0 auto;
  height: 600px;
}

.blackboard .wings {
  position: absolute;
  z-index: 0;
  bottom: 0;
  height: 500px;
  left: 10%;
}

.blackboard .spliter_fifty .blurb {
  top: 170px;
  left: 15%;
  position: absolute;
  z-index: 1;
  width: 80%;
}

.blackboard .spliter_fifty .blurb h2 {
  font-size: 46px;
}

.blackboard .spliter_fifty .blurb p {
  font-size: 16px;
}

.blackboard h1 {
  padding: 60px 0 0 12% !important;
  font-size: 50px;
  text-align: left;
}

.center {
  text-align: center;
}

.left {
  text-align: left;
  padding-left: 12% !important;
}

.third {
  display: inline-block;
  vertical-align: top;
  width: 25%;
  padding: 4%;
  text-align: left;
  overflow-x: hidden;
}

.block {
  display: block;
  position: relative;
  padding-bottom: 40px;
  padding-left: 55px;
  width: 100%;
  overflow-x: hidden;
}

.block .fly {
  position: absolute;
  top: 5px;
  left: 0px;
  width: 60px;
}

.big_third {
  width: 53% !important;
  padding: 0 3%;
  text-align: left;
}

.big_third .modern {
  display: block;
  margin: 50px auto 20px auto;
  height: 25vw;
}

.third h3 {
  color: #D50367;
  text-transform: uppercase;
}

.third .buste {
  display: block;
  margin: 0 auto;
  height: 380px;
}

.third .nomad {
  display: block;
  margin: 0 auto;
  height: 380px;
}

.third .button {
  margin-top: 20px;
  height: 40px;
  line-height: 36px;
  display: block;
  font-size: 15px;
  font-weight: 500;
  color: #000;
  padding: 0 40px 0 16px;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #000;
  border-radius: 20px;
  cursor: pointer;
  width: fit-content;
  position: relative;
}

.third .button svg {
  position: absolute;
  right: 10px;
  top: 12px;
}

.third .button:hover {
  background-color: #D50367;
  color: #FFF;
  border: 1px solid #D50367;
}

.third .board {
  display: block;
  width: 100%;
  margin: 40px auto;
  padding: 70px 5% 0 5%;
  height: 300px;
  background-color: #212121;
  position: relative;
  color: #EEE;
}

.third .board .origami {
  width: 160px;
  position: absolute;
  left: calc(50% - 80px);
  top: -80px;
}

.blackboard .folio_holder {
  display: inline-block;
  vertical-align: top;
  text-align: center;
  width: 44%;
  margin-top: 40px;
  margin-bottom: 80px;
  overflow-x: hidden;
}

.blackboard .folio_holder .visual {
  display: inline-block;
  vertical-align: top;
  height: 560px;
  z-index: 2;
}

.blackboard .folio_holder .announcer {
  display: inline-block;
  vertical-align: top;
  padding-left: 100px;
  margin: 80px 0;
  padding: 15px;
  width: 300px;
  height: 380px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  background: linear-gradient(170deg, #212121 0%, #6A6A6A 100%) !important;
  color: #FFF;
  text-align: left;
}

.blackboard .folio_holder .brand {
  display: block;
  margin: 10px auto 0 auto;
  width: 180px;
}

.blackboard .folio_holder .title {
  font-size: 16px;
  font-weight: 700;
  margin-top: 10px;
}

.blackboard .folio_holder .description {
  font-size: 15px;
  font-weight: 400;
  margin-top: 10px;
}

.blackboard .folio_holder .button {
  margin-top: 10px;
  height: 40px;
  line-height: 36px;
  display: block;
  font-size: 15px;
  font-weight: 500;
  padding: 0 40px 0 16px;
  background-color: rgba(0, 0, 0, 0);
  color: #C0C0C0;
  border: 1px solid #C0C0C0;
  border-radius: 20px;
  cursor: pointer;
  width: fit-content;
  position: relative;
  float: right;
}

.blackboard .folio_holder .button svg {
  position: absolute;
  right: 10px;
  top: 12px;
}

.blackboard .folio_holder .button:hover {
  background-color: #D50367;
  color: #FFF;
  border: 1px solid #D50367;
}

.news {
  margin-top: 100px;
  display: block;
  overflow-x: hidden;
}

.news .item {
  display: inline-block;
  vertical-align: top;
  margin: 20px;
  background-position: center center;
  background-size: cover;
  border-radius: 8px;
  width: 80px;
  height: 80px;
  cursor: pointer;
}

.news a {
  display: block;
  font-weight: 700;
  margin-top: 30px;
  font-size: 16px;
  color: #D50367;
  text-decoration: none;
}

.news textarea {
  width: 90%;
  height: 100px;
  resize: none;
  border: 1px solid #C0C0C0;
  border-radius: 10px;
  padding: 10px 20px;
  font-family: "Segoe UI", Arial, sans-serif;
  margin-bottom: 10px;
  display: block;
}

.news input {
  width: 90%;
  height: 40px;
  resize: none;
  border: 1px solid #C0C0C0;
  border-radius: 10px;
  padding: 10px 20px;
  font-family: "Segoe UI", Arial, sans-serif;
}

footer {
  margin-top: 100px;
  color: #FFF;
  padding-bottom: 20px;
  overflow-x: hidden;
}

footer .logo {
  margin-left: 0;
}

footer .button {
  color: #C0C0C0 !important;
  border: 1px solid #C0C0C0 !important;
}

footer .button:hover {
  color: #FFF !important;
  border: 1px solid #D50367 !important;
}

.hcaptcha {
  scale: 0.9;
  display: block;
  text-align: left;
  margin: 10px 0 0 -38px;
}

.badge_success {
  display: block;
  padding: 2px 12px;
  font-size: 11px;
  font-weight: 500;
  color: #000;
  background-color: #1fe01f;
  margin: 5px 0;
  width: fit-content;
  border-radius: 10px;
}

.badge_danger {
  display: block;
  padding: 2px 12px;
  font-size: 11px;
  font-weight: 500;
  color: #000;
  background-color: #fb6363;
  margin: 5px 0;
  width: fit-content;
  border-radius: 10px;
}

.privacy {
  padding: 20px 10%;
}

.smaller {
  font-size: 11px;
}

.cookies{
  position: fixed;
  bottom: 20px;
  left: 20px;
  padding: 4px 10px;
  background-color: #C0C0C0;
  border-radius: 4px;
  max-width: 240px;
  font-size: 12px;
  z-index: 3;
}

.cookies .close{
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 12px;
  color: #000;
}

@media screen and (max-width: 940px) {
  body {
    overflow-x: hidden;
    padding: 0;
    margin: 0;
  }

  h1 {
    font-size: 30px;
  }

  header {
    height: auto;
  }

  .logo {
    height: 56px;
    margin: 0 8px 0 20px;
  }

  .logo_text {
    line-height: 60px;
    font-size: 29px;
    font-weight: 700;
  }

  .left {
    padding-left: 10px !important;
  }

  header .left_board {
    width: 100%;
    display: block;
  }

  header .right_board {
    width: 90%;
    display: none;
  }

  header .right_board .entry {
    margin-right: 20px;
  }

  header .right_board .button {
    display: none;
  }

  .spliter_one {
    width: 80%;
    margin: 20px auto;
    display: block;
  }

  .spliter_one p {
    margin-top: 30px;
  }

  .spliter_two {
    width: 80%;
    margin: 20px auto;
    display: block;
  }

  .spliter_two h1 {
    margin-top: 30px;
    font-size: 40px;
  }

  .blackboard {
    width: 100vw;
  }

  .blackboard h1 {
    padding: 60px 0 0 20px !important;
    font-size: 42px;
  }

  .blackboard .spliter_fifty {
    width: 100%;
    margin: auto;
    display: block;
  }

  .blackboard .spliter_fifty .blurb {
    top: 70px;
    left: 5%;
    position: relative;
    z-index: 1;
    width: 85%;
  }

  .blackboard .spliter_fifty .blurb h2 {
    font-size: 40px;
  }

  .blackboard .wings {
    display: none;
  }

  .blackboard .portrait {
    display: block;
    margin: 0 auto;
    height: auto;
    width: 80%;
  }

  .third {
    display: block;
    width: 92%;
  }

  .third .board {
    width: 80%;
  }

  .big_third {
    display: block;
    width: 92% !important;
  }

  .big_third .modern {
    height: 200px;
  }

  .third .nomad {
    display: block;
    margin: 0 auto;
    height: auto;
    width: 80%;
  }

  .block {
    width: 90%;
    padding-left: 0;
  }

  .block .fly {
    display: none;
  }

  .blackboard .folio_holder {
    display: block;
    width: 100%;
    padding-bottom: 20px;
  }

  .blackboard .folio_holder .visual {
    display: block;
    width: 80%;
    margin: 0 auto;
    height: auto;
  }

  .blackboard .folio_holder .announcer {
    display: block;
    margin: 40px auto;
    width: 80%;
    height: auto;
    padding-bottom: 80px;
    border-radius: 20px;
  }

  .news textarea {
    width: 90%;
  }

  .news input {
    width: 90%;
  }

  .hcaptcha {
    margin-left: 0;
  }
}